.about-container{
    display: flex;
    justify-content: center;
    @media (min-width: 1400px)
    {
        max-width: 1600px;
    }

}

.titlspn{
    color: wheat;
    font-style: italic;
}

.infobox{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 0.5em;
    /* align-content: flex-end;
    align-items: flex-start; */
    color: rgb(162, 167, 196);
    font-family: "scope one";

}


.border-none{
    border-color: transparent;
    border-bottom: 0px;
}

.card{
    border: 0;
    .blog-card{
        background-color: #5ea1a3;
    }
    .main-card-img{
        border-top-left-radius:0;
        border-top-right-radius:0;
    }
    .anime{
        background-color: #4c8d8f;
    }
    .dev{
        background-color: #85874b;
    }
    .other{
        background-color: #894b87;
    }
}

.home-card{
    .card-header{
        color: white;
        font-family: 'Allerta Stencil';
    }
    .card-title{
        color:#f9f9f9;
    }
    .card-text{
        color: #bfcdd5;
        font-family: 'Roboto serif';
    }
}

.car-img{
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0rem !important;
}


.float-right{
    float: right;
}

.blog-title{
    font-size: clamp(2.75rem, 2.1778rem + 1.831vw, 4.375rem);
    font-weight: 600;
}
.subtitle{
    font-size: 2rem;
    font-family: monospace;
    color: #d7c665;
}

.post-text{
    font-size: 1.5rem;
    text-align: justify;
    p{
        font-family: "Roboto Serif";
    }
    ul{
        font-family: "Roboto Serif";
    }
    @media (max-width: 767px)
    {
        font-size: 18px;
        font-weight: 300;
    }
}

.post-content{
    padding-left: 7rem;
    padding-right:7rem;
    color: white;
    @media (max-width:991px) {
        padding-left: 0px;
        padding-right:0rem;
    }
    p{
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }
    img{
        margin-left: calc(-7rem);
        margin-right: calc(-7rem);
        width: -webkit-fill-available;

        @media (max-width:991px) {
            margin-left: 0px;
            margin-right: 0px;
            width: 100%;
        }
    }

}

.toggle-container{
    display: inline;
    font-style: italic;
}
.hidden {
    display: none;
    color: #d7c665;
} 
.shown {
    display: inline;
    color: #dadada;
}
.toggle-container:hover .shown {
    display: none;
} 
.toggle-container:hover .hidden {
    display: inline;
}
.tldr{
    background-color: black;
    color: white;
    border-radius: 30px;
    font-size: 0.6em;
    cursor: pointer;
    transition: ease-out 0.4s;
    &:hover{
        background-color: red;
        font-size: 0.7em;
    }
}

.anim-elem{
    transition: all ease-in-out 0.5s;
}
.collapsed{
    font-size: 0;
}
.expanded{
    font-size: auto;
}