.banner-style{
    overflow: hidden;
    position: relative;
}

.banner-text{
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fffff2;
    font-family: Allerta Stencil;
    font-size: 70px;
    text-wrap: nowrap;
    @media (max-width: 1250px) {
        transform: translate(-50%, -50%);
        font-size: 50px;
      }
      @media (max-width: 1250px) {
        transform: translate(-50%, -50%);
        font-size: 50px;
      }
      @media (max-width: 768px) {

        font-size: 30px;
      }
      .subt{
        font-size: 0.35em;
        font-family: "Scope One";
        opacity: 0;
        transform: translateY(-20px);
        transition: all 0.5s ease;
      }
}


.banner-text:hover .subt {
    opacity: 1;
    transform: translateY(0);
}


.navbar-style{
    background-color: #17236d;
}

.navbar-nav{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-family: "Alata";
    font-size: 1.5rem;
}

.nav-link{
    color: white;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    &:hover{
        color: #737ba7;
    }
    &:focus{
        color: #737ba7;
    }
}